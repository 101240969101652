@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* @font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Font/Gotham-Light.otf');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Font/Gotham-Bold.otf');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Font/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Optima';
  src: url('./fonts/Optima/OPTIMA.woff');
}

@font-face {
  font-family: 'Optima Medium';
  src: url('./fonts/Optima/Optimamedium.woff');
}

@font-face {
  font-family: 'Optima Bold';
  src: url('./fonts/Optima/OPTIMA_B.woff');
} */

:root {
  /* Color */
  --primary: #607980;
  --black: #000000;
  --white: #fff;
  --lightbg: #f5f8f9;

  /* Fonts */
  --font_1: "Montserrat", sans-serif;
  --font_2: "Montserrat", sans-serif;
  --font_3: "Montserrat", sans-serif;

  --primary-base: "Trajan-Pro";
  --primary-medium: "Trajan-Pro";
  --primary-bold: "Trajan-Pro";
}

@font-face {
  font-family: "Trajan-Pro";
  src: url("./fonts/Trajan-Pro/TrajanPro-Regular.ttf");
}
/* @font-face {
  font-family: 'Trajan-Pro';
  src: url('./fonts/Trajan-Pro/TrajanPro-Bold.otf');
} */

@media (max-width: 1024px) {
  body,
  html {
    overflow-x: hidden;
  }
}

body {
  font-family: var(--font_1);
}

input::placeholder {
  color: #14344a !important;
  opacity: 20% !important;
}
p {
  font-family: "Montserrat", sans-serif !important;
}

/* BANNER SLIDER */
.rcs_hero_img h1 {
  font-family: var(--primary-base);
  font-size: 50px;
  text-transform: uppercase;
  line-height: 46px;
  color: #000;
}

.rcs_hero_img p {
  font-size: 18px;
  padding: 10px 0 20px;
  margin: 0;
  color: #000;
}

.rcs_hero_img .outline-base-btn {
  background-color: transparent;
  border: 1px solid var(--black);
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
}

.outline-base-btn {
  font-size: 16px;
  border-radius: 0;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  display: inline-block;
  font-family: var(--font_1);
}

.rcs_hero_img .outline-base-btn:hover,
.rcs_hero_img .outline-base-btn:active,
.rcs_hero_img .outline-base-btn:focus {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--white);
}

.outline-base-btn.large {
  padding: 12px 20px;
}

.outline-base-btn.small {
  padding: 5px 15px;
}

.rcs_hero_slider {
  min-height: 750px;
  display: grid;
  align-items: center;
  background-size: cover;
  background-position: right top;
}
.heading-wrapper {
  padding-bottom: 35px;
}

.heading-wrapper span {
  font-size: 24px;
  line-height: 26px;
  font-family: var(--primary-base);
  padding-bottom: 5px;
  display: block;
  color: var(--primary);
}

.heading-wrapper h2 {
  font-family: var(--primary-medium);
  color: #272727;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 15px;
  font-weight: 400 !important;
}
.sd_btn_outline {
  border: 1px solid var(--primary);
  background-color: transparent;
  color: var(--primary);
  padding: 10px 30px;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
}
.sd_btn_outline:hover {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  transition: all 0.3s ease-in;
}
.shop-ring-by-style .item img {
  width: 93%;
  margin: 0 auto;
}
.rcs_signup_to_section .heading-wrapper h2 {
  font-family: var(--primary-medium);
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 15px;
}
.heading-wrapper p {
  font-size: 16px;
  line-height: 23px;
  color: var(--black);
  margin: 0;
  font-weight: 400;
}
.rcs_signup_to_section .heading-wrapper p {
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin: 0;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: left;
}
.sd_get_in_touch_box_inner img {
  margin-right: 10px;
  width: 20px;
  height: auto;
}
.sd_get_in_touch_box_inner {
  margin-bottom: 10px;
}
.sd_get_in_touch_box_inner h6 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 500;
  font-family: var(--font_1);
}
.sd_get_in_touch_footer_inner p {
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin: 0;
  margin-bottom: 0px;
  font-weight: 400;
  word-break: break-all;
}
.sd_get_in_touch_footer_inner {
  cursor: pointer;
}
.sd_get_in_touch_footer ul li {
  display: inline-block;
  width: 33%;
}
.sd_get_in_touch_footer ul li:nth-child(1) {
  width: 40%;
}
.sd_get_in_touch_footer ul li:nth-child(2) {
  width: 30%;
}
.sd_get_in_touch_footer ul li:nth-child(3) {
  width: 20%;
}

.sd_get_in_touch_footer ul {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.gs_news_form {
  width: 80% !important;
}
.sd_sign_border_left {
  border-left: 1px solid #ffffff30;
  height: auto;
  padding: 60px 15px 60px 50px;
}
.sd_shop_diamond_collection .slick-prev,
.sd_shop_diamond_collection .slick-next {
  width: 30px;
  height: 30px;
}
.sd_about_btn_left {
  text-align: left;
}
.sd_insta_img img {
  width: 160px;
  margin: 0 auto;
}
.section-space {
  padding: 50px 0;
}

.showcase-container {
  /* padding: 0 100px; */
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.diamonds-collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  row-gap: 15px;
  position: relative;
}
.diamonds-collection ul li {
  display: inline-block;
  width: 10%;
  margin-bottom: 20px0;
}
/* 
.diamonds-collection::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  width: 10px;
  height: 100%;
  background: url("./img/divider.png") no-repeat center/cover;
} */

.diamonds-collection .diamond-item {
  text-align: center;
  cursor: pointer;
}

.diamonds-collection .diamond-item img {
  width: 85px;
  margin: auto;
}

.diamonds-collection .diamond-item p {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font_1);
  padding-top: 10px;
}

.diamonds-item-brief {
  width: 80%;
  margin: 0 auto;
}

.diamonds-item-brief .content h2 {
  font-family: var(--primary-medium);
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1px;
}

.diamonds-item-brief .content p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.diamonds-item-brief img {
  max-width: 280px;
  height: 280px;
  object-fit: contain;
  margin-right: 20px;
}

.diamonds-item-brief .content .outline-base-btn {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.shop-diamond-by-shape {
  background-color: var(--primary);
}

.diamonds-item-brief .content .outline-base-btn:hover {
  color: var(--white);
  background-color: var(--primary);
}

.shop-diamond-by-shape {
  position: relative;
}
.shop-diamond-by-shape .heading-wrapper h2,
.shop-diamond-by-shape .heading-wrapper p {
  color: var(--white);
}
.diamonds-collection .diamond-item p {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font_1);
  padding-top: 10px;
  color: var(--white);
  font-weight: 400;
  margin-bottom: 0;
}

.shop-diamond-by-shape::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
  display: none;
}

.explore-collection .item img {
  max-width: 100%;
  transition: all 0.5s ease;
}

/* .explore-collection .item:hover img {
  transform: scale(1.1);
} */

.explore-collection .item a {
  font-size: 20px;
  line-height: 25px;
  color: var(--black);
  padding-top: 10px;
  display: block;
  font-weight: 400;
  text-align: center;
}

.explore-collection .item:hover a {
  font-weight: 500;
}

.explore-collection .item .img-box {
  overflow: hidden;
  padding-top: 0px;
}

.design-diamond-ring-section {
  background-color: #fafafa;
  padding: 80px;
  margin: 50px auto;
}

.design-diamond-ring-section .heading-wrapper * {
  color: var(--black);
}

.design-diamond-ring-section .heading-wrapper p {
  width: 85%;
  margin: 0 auto 20px;
}
.design-diamond-ring-section .heading-wrapper h2 {
  font-size: 50px;
  text-transform: uppercase;
}
.sd_start_with_setting_whitebox .heading-wrapper h2 {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 400 !important;
  line-height: 35px;
}

.design-diamond-ring-section .heading-wrapper span {
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  font-family: var(--font_1);
  padding-bottom: 15px;
}

.design-diamond-ring-section .heading-wrapper span {
  font-weight: 400;
}

.design-diamond-ring-section .content span {
  display: inline-block;
  opacity: 0.15;
  font-size: 50px;
  line-height: 60px;
  font-family: var(--primary-bold);
  padding-bottom: 15px;
}

.design-diamond-ring-section .content * {
  color: var(--white);
}

.design-diamond-ring-section .content h3 {
  font-family: var(--primary-base);
  font-size: 26px;
  line-height: 25px;
  padding-bottom: 15px;
  margin: 0;
}

.design-diamond-ring-section .content p {
  padding-bottom: 25px;
  font-size: 14px;
  margin: 0;
  width: 80%;
}

.design-diamond-ring-section .content .outline-base-btn {
  border: 1px solid var(--white);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  font-family: var(--font_1);
}

.design-diamond-ring-section .content .outline-base-btn:hover {
  background: #fff;
  color: #000;
}

.design-diamond-ring-section,
.design-diamond-ring-section .video-item {
  position: relative;
}

/* .design-diamond-ring-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("./img/shape.png") no-repeat center/cover;
  width: 200px;
  height: 200px;
} */

.design-diamond-ring-section .video-item video {
  width: 100%;
}

.design-diamond-ring-section .video-item .content {
  position: absolute;
  top: 0;
  left: 50px;
}

.shop-ring-by-style .item a {
  display: block;
  font-family: var(--font_1);
  color: var(--black);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  padding-top: 15px;
  text-transform: capitalize;
}

.shop-ring-by-style .item:hover a {
  font-weight: 500;
}

.shop-ring-by-style {
  position: relative;
}

.shop-ring-by-style .slick-slider .slick-arrow {
  position: absolute;
  /* top: -70px; */
  right: 0;
  left: auto;
  width: 30px;
  height: auto;
}

/* .shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
  right: 40px;
} */
.shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
  left: 0;
  top: 50%;
  z-index: 1;
}
.shop-ring-by-style .slick-slider .slick-arrow.slick-next {
  right: 0;
  top: 50%;
  z-index: 1;
}

.gemstone-glamour .heading-wrapper h2,
.gemstone-glamour .heading-wrapper span {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 30px;
}

.gemstone-glamour .heading-wrapper p {
  width: 100%;
  margin: 0 auto;
}

.gemstone-glamour .item {
  text-align: center;
}

.gemstone-glamour .item p {
  font-size: 12px;
  color: var(--white);
  padding-top: 10px;
}

.gemstone-glamour {
  /* background: url("./img/gemstone-bg.jpg") no-repeat center/cover; */
  background: url("../../../../Assets/images//swales-img//hand-creafted-ring-banner.png");
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  align-items: center;
}

.gemstone-glamour .heading-wrapper * {
  color: var(--black);
}

.gemstone-glamour .outline-base-btn {
  color: var(--black);
  border: 1px solid var(--black);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
}

.gemstone-glamour .outline-base-btn:hover {
  background: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.custom-design .heading-wrapper {
  width: 95%;
  margin: 0 auto;
}
.custom-design img {
  width: 100%;
}
.custom-design .outline-base-btn {
  border: 1px solid var(--primary);
  text-transform: uppercase;
  font-weight: 400;
}

.custom-design .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

.consult-specialist-section div[class*="col-"]:first-child {
  background-color: var(--primary);
}

.consult-specialist-section .inner-wrap {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  place-content: center;
  justify-content: center;
}

.consult-specialist-section .inner-wrap * {
  color: var(--white);
}

.consult-specialist-section .inner-wrap span {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--primary-base);
  text-transform: uppercase;
}

.consult-specialist-section .inner-wrap h2 {
  font-family: var(--primary-medium);
  text-transform: uppercase;
  font-size: 50px;
  line-height: normal;
}

.consult-specialist-section .inner-wrap p {
  font-size: 16px;
  line-height: 20px;
}

.consult-specialist-section .inner-wrap a {
  border: 1px solid var(--white);
  width: fit-content;
  margin: 0 auto;
}

.consult-specialist-section .inner-wrap a:hover {
  background: #fff;
  color: var(--primary);
}

.promises-section {
  position: relative;
}

.promises-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.promises-section .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promises-section .row div[class*="col-"] {
  position: relative;
}

.promises-section .row div[class*="col-"]:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 2px;
  height: calc(100% + 40px);
  background-color: var(--primary);
  opacity: 0.3;
}

.promises-section
  .row.align-items-center
  > div[class*="col-"]:first-child:before,
.promises-section .row div[class*="col-"]:last-child:before {
  display: none;
}

.promises-section .item p {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 5%;
  font-family: var(--font_2);
  margin-left: 15px;
}

.rcs_customer_say_section .outline-base-btn {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.rcs_customer_say_section .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

.rcs_customer_say_section {
  background-color: var(--primary);
}

.rcs_customer_say_section .heading-wrapper h2,
.rcs_customer_say_section .heading-wrapper p {
  color: #fff !important;
}
.rcs_customer_say_section .rcs_customer_slide_text_content p {
  font-size: 14px;
  line-height: 23px;
  color: var(--black);
  /* letter-spacing: 0.4px; */
  margin-bottom: 5px;
  font-family: var(--font_1) !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rcs_customer_say_section .rcs_customer_slide_text_content a {
  color: var(--black);
  font-family: var(--font_2);
  text-decoration: underline;
}

.rcs_customer_say_section .rcs_customer_review_slide_title h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.rcs_customer_say_section .MuiRating-sizeMedium {
  font-size: 1rem;
}

.rcs_customer_say_section .name-charAt {
  font-size: 20px;
  font-family: var(--font_1);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #0097a7;
  text-align: center;
  line-height: 35px;
  color: var(--white);
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.rcs_customer_say_section .name-charAt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #0097a7;
  opacity: 0.1;
  border-radius: 100%;
  z-index: -1;
}

.rcs_customer_say_section {
  padding: 50px 0;
}

.rcs_customer_say_section .slick-slide {
  padding: 10px;
}

.rcs_customer_say_section .rcs_customer_review_slide_item {
  padding: 15px 20px;
  border: 1px solid #dde0e1;
  background-color: var(--white);
}

.rcs_customer_review_slider .slick-prev,
.rcs_customer_review_slider .slick-next {
  top: -40px;
  width: 30px;
  height: 30px;
}
.rcs_customer_review_slider .slick-next {
  right: 0;
}
.rcs_customer_review_slider .slick-prev {
  right: 45px !important;
  left: auto;
}
.insta-iframe {
  height: 330px;
}
.rcs_signup_to_input_field input {
  height: 50px;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid rgba(255, 255, 255, 0.23);
  background: transparent;
}

.rcs_signup_to_input_field input::placeholder {
  color: #fff !important;
  opacity: 30% !important;
}

.rcs_signup_to_input_field input:active,
.rcs_signup_to_input_field input:focus {
  border: 1px solid #fff;
  background-color: transparent;
}

.rcs_signup_to_input_field .form-control:focus {
  color: #ffffffe9;
}
.rcs_signup_to_input_field button {
  background: #ffffffe6;
  color: var(--primary) !important;
  text-transform: uppercase;
  font-family: var(--font_1);
  letter-spacing: 1px;
  border: none;
  margin-left: 15px;
  font-weight: 500;
}
.rcs_signup_to_section {
  /* background: url("./img/newsletter-bg.jpg") no-repeat center/cover; */
  background-color: var(--primary);
  border-bottom: 1px solid #ffffff2e;
}

.bannerskel {
  height: 750px !important;
  transform: inherit !important;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .showcase-container {
    padding: 0 0;
    max-width: 1150px;
  }
  .rcs_hero_slider {
    min-height: 450px;
  }
  .bannerskel {
    height: 450px !important;
    transform: inherit !important;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 250px;
    height: 250px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 14px;
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    width: 97%;
  }

  .gemstone-glamour .item img {
    width: 85%;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 45px;
  }

  section.design-diamond-ring-section .content.pl-3.pr-5 {
    padding-right: 0px !important;
  }

  .section-space {
    padding: 60px 0;
  }

  .design-diamond-ring-section {
    padding-bottom: 70px !important;
  }

  .design-diamond-ring-section .video-item video {
    /* position: relative; */
    /* top: 120px; */
  }

  .design-diamond-ring-section .video-item .content {
    padding-right: 100px;
  }

  .design-diamond-ring-section .content p {
    font-size: 13px;
    width: 100%;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 12px;
  }

  .heading-wrapper h2 {
    font-size: 30px !important;
    line-height: 35px !important;
  }

  .heading-wrapper p {
    font-size: 13px;
  }

  .custom-design .heading-wrapper {
    width: 79%;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 40px;
  }

  .consult-specialist-section .showcase-container > div {
    width: 100%;
    overflow: hidden;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    /* height: 130px; */
  }

  .promises-section .item p {
    font-size: 16px;
  }

  .promises-section .heading-wrapper h2 {
    font-size: 28px;
  }

  .promises-section .row div[class*="col-"]:before {
    width: 1px;
    height: calc(100% + 20px);
  }

  .insta-iframe {
    height: 260px;
  }

  .rcs_footer_content_one .logo img {
    /* width: 90%; */
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .showcase-container {
    padding: 0 0;
    max-width: 1150px;
    width: 100%;
  }
  .rcs_customer_say_section .rcs_customer_review_slide_title h3 {
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
  }
  .time p {
    font-size: 12px;
  }
  .diamonds-collection .diamond-item img {
    width: 65px;
    margin: auto;
  }
  .diamonds-collection .diamond-item p {
    font-size: 14px;
    line-height: 22px;
    padding-top: 5px;
  }
  .gemstone-glamour {
    height: 330px;
  }
  .gemstone-glamour .heading-wrapper h2 {
    font-size: 25px !important;
    line-height: 25px !important;
  }
  .explore-collection .item img {
    max-width: 215px;
    transition: all 0.5s ease;
  }
  .sd_get_in_touch_footer_inner p {
    font-size: 11px;
  }
  .sd_get_in_touch_box_inner img {
    margin-right: 6px;
    width: 13px;
  }
  .sd_get_in_touch_box_inner h6 {
    font-size: 12px;
  }
  .sd_get_in_touch_box_inner {
    margin-bottom: 0px;
  }
  .rcs_signup_to_section .heading-wrapper p {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .wistia_responsive_padding {
    margin-bottom: 30px;
  }

  .RingBuilderHome .content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 13px;
  }

  .diamonds-collection .diamond-item img {
    width: 85px;
  }

  .section-space {
    padding: 50px 0;
  }

  .showcase-container {
    padding: 0 10px;
  }

  .rcs_hero_img h1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  .rcs_hero_img p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-top: 0;
  }

  .rcs_hero_img button {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  section.explore-collection.section-space .row {
    margin: 0;
  }

  .heading-wrapper h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .heading-wrapper p {
    font-size: 14px;
  }
  section.shop-diamond-by-shape.section-space .inner-shop-diamond-shape {
    padding: 0px 70px;
  }

  .diamonds-collection::after,
  .shop-diamond-by-shape::after {
    display: none;
  }

  .diamonds-collection .diamond-item .name {
    font-family: "Optima";
    font-size: 16px;
    font-weight: 400;
    color: var(--white);
    margin: 0;
  }

  .diamonds-collection .diamond-item .desc {
    font-family: "Gotham";
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }

  .diamonds-collection .diamond-item a {
    font-family: "Gotham";
    font-size: 12px;
    font-weight: 500;
    color: var(--primary);
    border: 1px solid var(--primary);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .design-diamond-ring-section .video-item {
    margin-bottom: 20px;
  }

  .slick-dots li button::before {
    width: 10px !important;
    content: " " !important;
    background: var(--primary) !important;
    height: 10px !important;
    border-radius: 50%;
    border: 1px solid var(--primary);
    padding: 1px;
  }
  .slick-dots li.slick-active button::before {
    opacity: 0.75;
    color: black;
    box-shadow: 0 0 0 2px #60798099;
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .shop-diamond-by-shape .heading-wrapper {
    padding-bottom: 0;
  }

  .explore-collection .item a {
    font-size: 15px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
  }
  .rcs_signup_to_section .heading-wrapper h2 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    text-align: left;
    width: 100% !important;
  }
  .heading-wrapper {
    width: 100%;
    margin: auto;
    text-align: center !important;
  }
  /* design-diamond-ring-section */

  .design-diamond-ring-section .heading-wrapper {
    text-align: center;
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper p {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper span {
    font-size: 20px;
    line-height: inherit;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }

  .mobile-slider-ringsetting-home .item {
    text-align: center;
  }

  .mobile-slider-ringsetting-home .item img {
    width: 50%;
    margin: 20px auto 30px;
  }

  .mobile-slider-ringsetting-home .item p {
    margin: auto;
  }

  .mobile-slider-ringsetting-home .item .content {
    padding: 0 !important;
  }

  .mobile-slider-ringsetting-home .slick-dots {
    position: absolute;
    top: 35px;
    bottom: auto;
    display: flex !important;
  }

  .mobile-slider-ringsetting-home .slick-dots li {
    width: 33%;
    height: 100%;
    border-right: 2px solid #ffffff51;
  }

  .mobile-slider-ringsetting-home .slick-dots li:last-child {
    border-right: 0;
  }

  .mobile-slider-ringsetting-home .slick-dots li a {
    font-family: "Optima";
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: auto;
    background: #ffffff41;
    color: #ffffff97;
  }

  .mobile-slider-ringsetting-home .slick-dots li.slick-active a {
    background: #fff;
    color: var(--primary);
  }

  .mobile-slider-ringsetting-home .slick-list {
    padding-top: 80px;
  }

  .design-diamond-ring-section {
    padding-bottom: 50px !important;
  }

  section.RingBuilderHome.design-diamond-ring-section.section-space
    .align-items-center.row {
    flex-direction: column-reverse;
  }

  .design-diamond-ring-section .video-item .content {
    position: static;
  }

  .design-diamond-ring-section .video-item video {
    width: 100%;
    /* position: relative; */
    /* top: -85px; */
    /* margin-bottom: -90px; */
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 5px;
    width: 80%;
    margin: 0 auto;
  }
  section.shop-ring-by-style.section-space .slick-dots {
    bottom: -45px;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
  }

  .gemstone-glamour .heading-wrapper {
    width: 100%;
  }

  .heading-wrapper span {
    font-size: 20px;
  }

  .gemstone-glamour {
    background-position: left;
  }

  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .custom-design .heading-wrapper {
    width: 90%;
    padding: 15px 0;
  }

  .consult-specialist-section .row {
    flex-direction: column-reverse;
  }

  .consult-specialist-section {
    padding: 20px;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 30px;
    font-weight: 550;
    padding: 10px 0;
  }

  .consult-specialist-section .inner-wrap span {
    font-size: 20px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap p {
    font-size: 12px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap {
    width: 100%;
    padding: 50px 0;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content a {
    font-size: 12px;
  }

  .insta-iframe {
    height: 210px;
  }

  .rcs_signup_to_input_field input,
  .rcs_signup_to_input_field button {
    min-width: 100% !important;
    /* margin-top: 10px; */
  }

  form.gs_news_form.w-100 .input-group {
    display: block;
  }

  .rcs_signup_to_section {
    /* background: url('../Assets/img/mb-bg-news.png'); */
    color: #fff !important;
    padding: 15px;
  }
  .gs_news_form {
    width: 100% !important;
  }

  .rcs_signup_to_input_field button {
    margin-left: 0;
    margin-top: 15px;
  }
  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_search_bar.mobileSearchBox {
    display: none;
  }

  .rcs_signup_to_input_field {
    margin-top: 15px;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  .rcs_hero_slider {
    min-height: 635px;
  }
  .bannerskel {
    height: 635px !important;
    transform: inherit !important;
  }

  section.explore-collection.section-space .col-md-3.col-6 {
    padding: 0 8px;
  }

  /* .rcs_customer_say_section .rcs_customer_slide_text_content p {
    height: 130px;
  } */

  .rcs_customer_say_section .rcs_customer_review_slide_title h3 {
    font-size: 13px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  section.shop-ring-by-style .slick-slide img {
    width: 100%;
    padding: 10px;
  }

  .promises-section .item p {
    font-size: 17px;
  }

  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .video-item video {
    margin-top: 55px;
  }

  section.design-diamond-ring-section.section-space.pb-0 {
    padding-bottom: 100px !important;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    right: 42px !important;
  }

  .rcs_bg-slide li {
    font-size: 13px !important;
    padding-bottom: 4px !important;
  }

  .appointment-report-btn .rcs_border_btn {
    font-size: 12px !important;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 70px !important;
    height: 70px !important;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap !important;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 36px;
  }

  .insta-iframe {
    height: 290px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
}

@media (min-width: 991px) and (max-width: 1279px) {
  .diamonds-collection .diamond-item img {
    margin: auto;
  }

  .rcs_hero_img h1 {
    font-size: 40px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .showcase-container {
    padding: 0 40px;
    overflow: hidden;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 200px;
    height: 200px;
    margin-right: 0px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 12px;
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    width: 100%;
    padding: 4px;
  }

  .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .gemstone-glamour .item {
    text-align: center;
    padding: 0 20px;
  }

  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 40px;
  }

  .section-space {
    overflow-x: hidden;
  }

  section.rcs_customer_say_section .slick-next {
    right: 0;
  }

  /* section.rcs_customer_say_section .slick-prev {
    left: 0;
  } */

  .consult-specialist-section .inner-wrap {
    padding: 30px 0;
    width: 100%;
  }

  .section-space {
    padding: 40px 0;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
    margin: 0 auto;
  }
}

.rcs_customer_review_slide_img img {
  width: auto;
}

@media (min-width: 767px) and (max-width: 1023px) {
  section {
    overflow-x: hidden;
  }

  .diamonds-collection .diamond-item img {
    /* width: auto !important; */
    margin: auto;
  }

  .diamonds-collection .diamond-item .desc {
    width: 75%;
    margin: auto !important;
  }

  .RingBuilderHome.design-diamond-ring-section .row .col-md-5.col-12,
  .RingBuilderHome.design-diamond-ring-section .row .col-md-7.col-12,
  section.gemstone-glamour .col-md-6,
  .consult-specialist-section .col-md-6,
  .custom-design .col-md-6,
  #rcs_footer_bottom .col-lg-4.col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-design .col-md-6 img {
    width: auto;
  }

  .insta-iframe {
    height: 175px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .gemstone-glamour {
    background-size: cover;
    background-position: right;
  }
}

@media (min-width: 540px) and (max-width: 991px) {
  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: nowrap;
  }

  .gemstone-glamour .item img {
    width: 70%;
  }

  .gemstone-glamour .item p {
    font-size: 10px;
  }

  .gemstone-glamour {
    /* background-image: linear-gradient(45deg, #2a3340, #1e252e); */
    height: 400px;
    position: relative;
    z-index: 1;
  }
  .gemstone-glamour::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    right: 0;
    background-color: #f3f1ec94;
    z-index: -1;
  }
}

@media (min-width: 769px) and (max-width: 990px) {
  .diamonds-collection .diamond-item .desc {
    line-height: 20px;
    min-height: 68px;
  }
  .diamonds-collection .diamond-item img {
    width: 85px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .sd_sign_border_left {
    border-left: 0;
    height: auto;
    padding: 0 15px;
  }
  .sd_get_in_touch_footer ul li {
    display: block;
    width: 100% !important;
    margin-bottom: 25px;
  }
  .sd_get_in_touch_footer ul {
    display: block;
  }
  .rcs_hero_slider {
    min-height: 468px;
    background-position: center;
    padding-bottom: 60px;
    align-items: end;
  }
  .bannerskel {
    height: 468px !important;
    transform: inherit !important;
  }
  .gemstone-glamour .heading-wrapper h2,
  .gemstone-glamour .heading-wrapper span {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
  }
  .sd_about_btn_left {
    text-align: center;
  }
  .explore-collection .item img {
    max-width: 80%;
    margin: 0 auto;
  }
  section.shop-diamond-by-shape.section-space .inner-shop-diamond-shape {
    padding: 0px 30px;
  }
  .gemstone-glamour {
    background: url("../../../../Assets/images//swales-img//hand-creafted-ring-banner-mobile.png");
    width: 100%;
    height: 550px;
    align-items: end;
    background-size: cover;
    background-position: top center;
    padding-bottom: 25px !important;
  }
  .shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
    left: 0;
    top: 50%;
  }
  .shop-ring-by-style .slick-slider .slick-arrow.slick-next {
    right: 0;
    top: 50%;
  }
  .rcs_customer_review_slider .slick-prev {
    left: 41% !important;
    bottom: -50px !important;
    top: auto;
    z-index: 1;
  }
  .rcs_customer_review_slider .slick-next {
    right: 41% !important;
    bottom: -50px !important;
    top: auto;
    z-index: 1;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .sd_get_in_touch_footer ul li {
    display: block !important;
    width: 100% !important;
    margin-bottom: 25px;
  }
  .sd_get_in_touch_footer ul {
    display: block;
  }
  .sd_start_with_setting_whitebox .heading-wrapper h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .sd_start_with_setting_whitebox img {
    margin-bottom: 30px;
    width: 215px;
  }
}
