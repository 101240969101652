.sl_signup_img_box {
	display: flex;
	align-items: center;
	gap: 15px;
	vertical-align: middle;
	align-items: center;
	margin-top: 20px;
  overflow: hidden;
}
.mail_online_image_box1 {
  border-radius: 12px;
  width: max-content;
  margin-bottom: 0;
  overflow: hidden;
  padding-top: 0;
}


.mail_online_image_box1 .mail_online_image {
	cursor: pointer;
	width: 200px;
	height: auto;

    max-width: 100%;
    transition: all .5s ease;
}
.mail_online_image_box1 .mail_online_image:hover {
  transform: scale(1.1);
  transition: all .5s ease;
  
}
.member_banner img {
  width: 35%;
  margin: 50px auto 0;
  cursor: pointer;
}



.member_banner {
	text-align: center;
}
.membership_modal .btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: initial;
  height: 1em;
  opacity: 0.5;
  padding: .25em;
  width: 1em;
  text-align: right;
}
.membership_modal .modal-body {
	padding: 0 20px 20px;
}
.membership_modal .modal-header {
	background-color: rgba(0, 0, 0, 0);
	padding: 10px 20px;
	border-bottom: 0;
}
.membership_modal .modal-content {
	border-radius: 8px;
}
/* wedding eng ring style */
.sd_eng_bg_img{
    background-image: url('../../Assets/images/swales-img/eng-ring.png');
    width: 100%;
    height: 400px;
    background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  margin-bottom: 30px;

}
.sd_wedd_bg_img{
    background-image: url('../../Assets/images/swales-img/wedding-ring.png');
    width: 100%;
    height: 400px;
    background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  margin-bottom: 30px;

}
.sd_start_with_setting_whitebox {
	box-shadow: 0px 4px 16px 0px #0000001A;
	padding: 30px;
	text-align: center;
	margin: 30px 0 0;
}
.sd_start_with_setting_whitebox img {
	margin-bottom: 30px;
	width: 366px;
	height: auto;
}
.sd_loose_diamond_Section{
    background-image: url('../../Assets/images/swales-img/loose-banner.png');
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    margin-bottom: 60px;
    display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10%;
}

/* Responsive style */
@media(min-width:320px) and (max-width:767px){
  .sl_signup_img_box {
    display: block;
    gap: 0;
  }
  .member_banner img {
    width: 100%;
    margin: 30px auto 0;
  }

  .mail_online_image_box1 {
    width: 100% !important;
  }
  
    .sd_loose_diamond_Section{
        background-image: url('../../Assets/images/swales-img/loose-banner-mobile.png');
        height: 300px;
        margin-bottom: 30px;
      align-items: end;
      background-position: top center;
      justify-content: center !important;
    padding: 15px 15px 30px;
    text-align: center;
    }
    .sd_wedd_bg_img, .sd_eng_bg_img {
        height: 210px;  
        display: flex;
        align-items: center;
        justify-content: left;
        padding-left: 15px;
        margin-bottom: 20px;
    }
 
 .sd_wedd_bg_img .heading-wrapper, .sd_eng_bg_img .heading-wrapper{
    text-align: left !important;
    padding-bottom: 0 !important;
}
.sd_start_with_setting_whitebox {
	box-shadow: 0px 4px 16px 0px #0000001A;
	padding: 20px 20px 30px;
	text-align: center;
	margin: 15px 20px;
}
.design-diamond-ring-section {
	padding-bottom: 0 !important;
}
.sd_start_with_setting_whitebox img {
	margin-bottom: 20px;
	width: 200px;
	height: auto;
}
.rcs_customer_review_slider .slick-prev {
  right: auto;
  left: 40%;
  bottom: -50px;
  z-index: 1;
}
.rcs_customer_review_slider .slick-next {
  left: auto;
  right: 40%;
  bottom: -50px;
  z-index: 1;
}
} 

@media(min-width:768px) and (max-width:991px){
  .member_banner img {
    width: 98%;
  }
  .rcs_hero_slider {
    min-height: 320px;
    display: grid;
    align-items: center;
    background-size: cover;
    background-position: right top;
  }
  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 5px;
    width: 97% !important;
    margin: 0 auto;
  }
  .sd_loose_diamond_Section {
    height: 230px !important;
    margin-bottom: 30px;
    padding-left: 8%;
  }
  .explore-collection .item img {
    max-width: 95%;
    transition: all .5s ease;
    margin: 0 auto;
  }
  .sd_eng_bg_img, .sd_wedd_bg_img {
    height: 230px;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .sd_eng_bg_img .heading-wrapper, .sd_wedd_bg_img .heading-wrapper{
    text-align: left !important;
    padding-bottom: 0 !important;
}
.custom-design .heading-wrapper {
  width: 98%;
  padding: 15px 0;
  text-align: center !important;
  margin: 0 auto;
}
.sd_about_btn_left {
  text-align: center;
}
.outline-base-btn.large {
  padding: 10px 20px;
  font-size: 14px;
}
.sd_btn_outline {
  padding: 10px 20px;
  font-size: 14px;
}
.sd_start_with_setting_whitebox img {
  margin-bottom: 30px;
  width: 190px;
  height: auto;
}
.insta-section .heading-wrapper {
	text-align: left !important;
  padding-bottom: 10px !important;
}
.sd_get_in_touch_footer ul {
	display: block;
	align-items: baseline;
}
.sd_get_in_touch_footer ul li {
	display: inline-block;
	width: 100% !important;
	margin-bottom: 34px;
}
.sd_get_in_touch_footer ul li:last-child {
	margin-bottom: 0;
}
.rcs_signup_to_section {
	padding: 30px 70px !important;
}
.rcs_signup_to_section .heading-wrapper {
	padding-bottom: 10px;
}
.sd_align_items_tab {
	align-items: baseline !important;
}
.sd_sign_border_left {
	border-left: 1px solid #ffffff30;
	height: auto;
	padding: 10px 0 10px 35px;
	height: 330px;
}
.sd_start_with_setting_whitebox {
	min-height: 360px;
}
}
@media(min-width:992px) and (max-width:1024px){
  .rcs_hero_slider {
    min-height: 320px;
    display: grid;
    align-items: center;
    background-size: cover;
    background-position: right top;
  }
  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 5px;
    width: 97% !important;
    margin: 0 auto;
  }
  .sd_loose_diamond_Section {
    height: 230px !important;
    margin-bottom: 30px;
    padding-left: 8%;
  }
  .explore-collection .item img {
    max-width: 95%;
    transition: all .5s ease;
    margin: 0 auto;
  }
}
@media(min-width:1025px) and (max-width:1199px){}
@media(min-width:1200px) and (max-width:1440px){
  .rcs_hero_slider {
    min-height: 450px;
  }
  .rcs_hero_img h1 {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  .rcs_hero_img p {
    font-size: 16px;
    padding: 10px 0 20px;
  }
  .outline-base-btn.large {
    padding: 10px 20px;
  }
  .rcs_hero_img .outline-base-btn {
    font-size: 12px !important;
  }
  .heading-wrapper h2 {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  section.shop-ring-by-style.section-space .slick-slide img {
    width: 93%;
  }
  .shop-ring-by-style .item a {
    font-size: 15px;
    line-height: 22px;
    padding-top: 10px;
  }
  .section-space {
    padding: 40px 0;
  }
  .heading-wrapper p {
    font-size: 12px;
  }
  .sd_start_with_setting_whitebox .heading-wrapper h2 {
    font-size: 20px !important;
    line-height: 23px !important;
  }
  .sd_start_with_setting_whitebox img {
    margin-bottom: 20px;
    width: 170px;
  }
  .sd_btn_outline {
    padding: 10px 20px;
    font-size: 12px;
  }
  .design-diamond-ring-section {
    padding: 50px !important;
  }
  .design-diamond-ring-section .heading-wrapper p {
    width: 75%;
    margin: 0 auto 20px;
  }
  .sd_eng_bg_img, .sd_wedd_bg_img {
    height: 320px;
    padding-left: 40px;
    margin-bottom: 30px;
  }
  .heading-wrapper {
    padding-bottom: 10px;
  }
  .explore-collection .item a {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
  }
  .rcs_customer_review_slide_img img {
    width: 60px;
  }
  .rcs_customer_say_section .rcs_customer_slide_text_content a {
    font-size: 12px;
  }
  .rcs_customer_slide_text_content {
    margin-bottom: 10px !important;
  }
  .rcs_customer_slide_text_content .css-ryrseu-MuiRating-root {
    font-size: 16px !important;
    margin-top: 10px !important;
  }
  .sd_insta_img img {
    width: 150px;
    margin: 0 auto;
  }
  .sd_loose_diamond_Section {
    height: 320px;
    margin-bottom: 40px;
    padding-left: 10%;
  }
  .custom-design .heading-wrapper {
    width: 92%;
  }
  .rcs_customer_review_slider .slick-prev, .rcs_customer_review_slider .slick-next {
    bottom: 0;
    width: 30px;
    height: 30px;
  }
  /* .rcs_customer_review_slider .slick-prev {
    right: auto;
    left: 40%;
    bottom: -50px;
    z-index: 1;
  }
  .rcs_customer_review_slider .slick-next {
    left: auto;
    right: 40%;
    bottom: -50px;
    z-index: 1;
  } */
}
